.App{
  width: 100%;
  display: flex;
  padding-top: 24px;
}
.App .dashboard{
  width: 100%;
  max-width: 1300px;
  margin: auto;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}
.chart-container{
  display: block;
  box-sizing: border-box;
  background-color: white;
  padding: 24px;
  border: 1px RGBA(0,0,0,0.05) solid;
  border-radius: 12px;
  margin-top: 48px;
}
.App .dashboard h2{
  display: block;
  margin: auto;
  margin-top: 0;
  margin-top: 12px;
  text-align: center;
}
.App .dashboard h2 img{
  height: 1em;
  display: inline-block;
  margin-top: 0.3em;
}
.price-value{
  font-size: 48px;
  font-weight: 700;
  margin-top: 12px;
  width: max-content;
  margin: auto;
  position: relative;
}
.price-change{
  position: absolute;
  right: -1em;
}
.price-label{
  display: block;
  width: max-content;
  box-sizing: border-box;
  background-color: rgb(236, 255, 232);
  padding: 0.5em 1.6em;
  border-radius: 20em;
  color: rgb(41, 153, 39);
  margin: auto;
  margin-top: 12px;
}